import * as React from 'react';

import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

import ImplantationSingleCard from '../../../components/ImplantationSingleCard/ImplantationSingleCard';
import PageBannerStyle from '../../../components/PageBannerStyle/PageBannerStyle';
import ScrollPageNav from '../../../components/ScrollPageNav/ScrollPageNav';
import Seo from '../../../components/Seo/Seo';
import SliderComponent from '../../../components/SliderComponent/SliderComponent';
import Layout from '../../../Layout';
import { Button } from '../../../Molecules/Button/Button';
import GraphRH from '../../../Molecules/GraphRH/GraphRH';
import SearchBar from '../../../Molecules/SearchBar/SearchBar';
import TitlePage from '../../../Molecules/TitlePage/TitlePage';
import TitleSection from '../../../Molecules/TitleSection/TitleSection';

import './styles.scss';

let classNames = require('classnames');

const OurStrategyPage = () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: {eq: "slider-image.png"}) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  const onSearchSubmit = (e) => {
    e.preventDefault();
  };

  const slider_setting_cards = {
    className: 'hash_dots_slider',
    centerMode: false,
    infinite: false,
    centerPadding: '5px',
    speed: 500,
    initialSlide: 0,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  const europeCards = [
    {
      visuel: data.file.childImageSharp,
      title: 'AD Shipping Pte / AB1 Shipping Pte Ltd.',
      adresse: '7 Temasek Boulevard #20-01 Suntec Tower One - Singapour Singapour 038987 Singapour',
      details: '<p>Tel. 01 30 15 69 00</p><p>Tel. 01 30 71 91 63</p><p>Email. Lorem @ipsum.com</p><p>http://www.aximum.fr</p>'
    },
    {
      visuel: data.file.childImageSharp,
      title: 'AD Shipping Pte / AB1 Shipping Pte Ltd.',
      adresse: '7 Temasek Boulevard #20-01 Suntec Tower One - Singapour Singapour 038987 Singapour',
      details: '<p>Tel. 01 30 15 69 00</p><p>Tel. 01 30 71 91 63</p><p>Email. Lorem @ipsum.com</p><p>http://www.aximum.fr</p>'
    },
    {
      visuel: data.file.childImageSharp,
      title: 'AD Shipping Pte / AB1 Shipping Pte Ltd.',
      adresse: '7 Temasek Boulevard #20-01 Suntec Tower One - Singapour Singapour 038987 Singapour',
      details: '<p>Tel. 01 30 15 69 00</p><p>Tel. 01 30 71 91 63</p><p>Email. Lorem @ipsum.com</p><p>http://www.aximum.fr</p>'
    },
    {
      visuel: data.file.childImageSharp,
      title: 'AD Shipping Pte / AB1 Shipping Pte Ltd.',
      adresse: '7 Temasek Boulevard #20-01 Suntec Tower One - Singapour Singapour 038987 Singapour',
      details: '<p>Tel. 01 30 15 69 00</p><p>Tel. 01 30 71 91 63</p><p>Email. Lorem @ipsum.com</p><p>http://www.aximum.fr</p>'
    },
    {
      visuel: data.file.childImageSharp,
      title: 'AD Shipping Pte / AB1 Shipping Pte Ltd.',
      adresse: '7 Temasek Boulevard #20-01 Suntec Tower One - Singapour Singapour 038987 Singapour',
      details: '<p>Tel. 01 30 15 69 00</p><p>Tel. 01 30 71 91 63</p><p>Email. Lorem @ipsum.com</p><p>http://www.aximum.fr</p>'
    }
  ];

  return (
    <Layout>
      <Seo
        title="Colas About US"
        description="Description de la page About us"
      />
      <div className={classNames('page_template', 'implantations_page')}>
        <PageBannerStyle
          iframeURL="https://colas-implantations.preprod.latitude-cartagene.com/"
          breadcrumbData={{
            grandParentPage: { title: 'Acceuil', url: '/' },
            parentPage: { title: 'Finance', url: '/' },
            currentPage: { title: 'Réunion des analystes', url: '/finance/analyst-meeting' }
          }}
        >
          <div className="wrapper_page">
            <TitlePage
              color="color_white"
              title="Nos implantations"
            />
            <div className="col_2">
              <div className="col graph">
                <GraphRH
                  text="Default Text"
                  jobs="65"
                  textColor="color_white"
                />
              </div>
              <div className="col text">
                <p>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Morbi aliquet augue mi, et rhoncus felis viverra eu. Nam quis viverra massa. Donec nec lacus in ex fringilla varius. Nunc dignissim elit sed varius luctus. Ut non nunc fermentum, varius leo id, convallis turpis. Integer finibus justo tortor. Fusce bibendum arcu eget tortor luctus fringilla.
                </p>
              </div>
            </div>
          </div>
        </PageBannerStyle>

        <section className={classNames('section_content', 'search_result_offices')}>
          <div className="wrapper_page">
            <SearchBar
              onSearchSubmit={onSearchSubmit}
              placeholder="Paris"
              buttonLabel="Rechercher"
            />
            <div className="search_results">
              <p className="nb_results">4 résultats corresponde à votre recherche</p>
              <div className="lists_results">
                {
                  europeCards.map((card, index) => (
                    <ImplantationSingleCard
                      key={index}
                      visuel={card.visuel}
                      title={card.title}
                      adresse={card.adresse}
                      details={card.details}
                    />
                  ))
                }
              </div>
            </div>
          </div>
        </section>
        <section className={classNames('section_content', 'section_region_list')}>
          <div className="wrapper_page">
            <TitleSection
              title="Europe"
              description="24 sites implantés"
              type="line"
            />
            <div className="listCardsDesktop">
              {
                europeCards.map((card, index) => (
                  <ImplantationSingleCard
                    key={index}
                    visuel={card.visuel}
                    title={card.title}
                    adresse={card.adresse}
                    details={card.details}
                  />
                ))
              }
            </div>
            <div className="listCardsMobile">
              <SliderComponent settings={slider_setting_cards}>
                {
                  europeCards.map((card, index) => (
                    <ImplantationSingleCard
                      key={index}
                      visuel={card.visuel}
                      title={card.title}
                      adresse={card.adresse}
                      details={card.details}
                    />
                  ))
                }
              </SliderComponent>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default OurStrategyPage;
